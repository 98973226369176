import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import addEditHeaderSummary from './addEditHeaderSummary';
export default {
  name: 'CustomerRuleMapping',
  components: { DatePicker, addEditHeaderSummary },
  watch: {
    currentPage: function() {
      this.getdunningHeaderSummaryGridList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getdunningHeaderSummaryGridList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      legalEntity: {
        value: null,
        text: null
      },
      dunning: {
        value: null,
        text: null
      },
      dunningSummaryRowDetails: null,
      showDunningSummaryModal: false,
      customerName: null,
      erpCustomerNumber: null,
      customerShortName: null,
      selectAllCheckBox: false,
      active: false,
      ruleName: {
        value: null,
        text: null
      },
      defaultValue:{
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      emailSendStatus: {
        value: null,
        text: null
      },
      dunningCriteria:{
        text:null,
        value:null
      },
      creationFromDate: null,
      creationToDate: null,

      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      vsetCode: null,
      setTimeVsetCode: null,
      dunningHeaderSummmaryData: [],
      dunningHeaderSummmaryFields: [
        {
          key: 'select_all',
          label: 'Select All',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 're_send_mail'
        },
        {
          key: 'party_name'
        },
        {
          key: 'short_name'
        },
        {
          key: 'erp_customer_no',
          label: 'ERP Customer No'
        },
        {
          key: 'rule_name'
        },
        {
          key: 'dunning_letter_no'
        },
        {
          key: 'sent_status',
          label: 'Email Sent Staus'
        },
        {
          key: 'sent_status_meaning'
        },
        {
          key: 'email_id'
        },
        {
          key: 'bcc_email',
          label: 'BCC Email'
        },
        {
          key: 'cc_email',
          label: 'CC Email'
        },
        {
          key: 'total_outstanding'
        },
        {
          key: 'dunning_letter_date'
        },
        {
          key: 'no_of_invoices'
        },
        {
          key: 'account_number'
        },
        {
          key: 'site_name'
        }
      ],
      showAlert:false,
      isSuccess: false,
      responseMsg: '',
    };
  },
  validations: {
    legalEntity: {
      value: {
        required
      }
    },
    dunning: {
      value: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showDunningSummaryModal = true;
          this.dunningSummaryRowDetails = null;
        }
        if (
          actionName === 'download' &&
          !this.showDunningSummaryModal &&
          !this.showValueSetModal
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'dunningAdmin/getdunningHeaderSummaryGridList',
            'dunning',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getdunningHeaderSummaryGridList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        customer_name: this.customerName,
        short_name: this.customerShortName,
        erp_customer_no: this.erpCustomerNumber,
        le_id: this.legalEntity.value,
        dunning: this.dunning.value,
        dunning_criteria:this.dunningCriteria.value,
        email_sent_status: this.emailSendStatus.value,
        rule_id: this.ruleName.value,
        creation_from_date: this.creationFromDate,
        creation_to_date: this.creationToDate
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/getdunningHeaderSummaryGridList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.dunningHeaderSummmaryData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },

    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }  else if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_BASED_ON) {
        this.dunning = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.DUNNING_RULE_LIST) {
        this.ruleName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DUNNING_EMAIL_STATUS
      ) {
        this.emailSendStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      else if (
        this.vsetCode === appStrings.VALUESETTYPE.DUNNING_CRITERIA
      ) {
        this.dunningCriteria = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
      this.showValueSetModal = false;
    },

    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },

    clear() {
      this.customerName = null;
      this.erpCustomerNumber = null;
      this.customerShortName = null;
      this.legalEntity= {
        value: null,
        text: null
      };
      this.dunning = {
        value: null,
        text: null
      };
      this.emailSendStatus = {
        value: null,
        text: null
      };
      this.creationFromDate = null;
      this.creationToDate = null;
      this.ruleName = {
        value: null,
        text: null
      };
      this.dunningCriteria={
        text:null,
        value:null
      };
      this.dunningHeaderSummmaryData = [];
      this.parent_value_set_id = null;
      this.totalRows = null;
    },
    checkUncheckAll() {
      this.customerCriteriaMappingData = this.customerCriteriaMappingData.map(
        data => {
          data.select_all = this.selectAllCheckBox;
          return data;
        }
      );
    },
    selectBoxChecked(flag, index) {
      this.customerCriteriaMappingData[index].select_all = flag;
    },
    rowSelected(item) {
      this.dunningSummaryRowDetails = item;
      this.showHideDunningSummaryModal(true);
    },
    showHideDunningSummaryModal(flag) {
      this.showDunningSummaryModal = flag;
    },
    truncateString(str) {
      if (str) {
        return str.replace(/,/g,'\n');
      }
    },
    clearVsetValues(vsetCode) {
      switch(vsetCode){
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = this.defaultValue
          break;
          case appStrings.VALUESETTYPE.DUNNING_BASED_ON:
            this.dunning = this.defaultValue
            break;
      case appStrings.VALUESETTYPE.DUNNING_RULE_LIST:
        this.ruleName = this.defaultValue
         break;
      case appStrings.VALUESETTYPE.DUNNING_EMAIL_STATUS:
         this.emailSendStatus = this.defaultValue
         break;
       case appStrings.VALUESETTYPE.DUNNING_CRITERIA:
         this.dunningCriteria = this.defaultValue
         break;
      }
    },
    resendMailDunningHeaderSummary(dunningHdrId){
      const payload = {
        dunningHdrId: dunningHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('dunningAdmin/resendMailDunningHeaderSummary', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
